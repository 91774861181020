import React, { useState, useEffect } from 'react';
import './Feed.css';
import axios from 'axios';
import { debounce } from 'lodash';

import stockUpImage from '../images/stock_up.png';
import stockDownImage from '../images/stock_down.png';


function Feed() {
    const [tweets, setTweets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [nextPage, setNextPage] = useState('/api/news?page=1');
    const [hasMore, setHasMore] = useState(true); // State to track if there are more pages to load

    async function fetchCompanyData(ticker) {
        try {
            const response = await axios.get(`/api/company/${ticker}`);
            return response.data.company_name;
        } catch (error) {
            console.error(`Error fetching company data for ticker ${ticker}: `, error);
            return ""; // Return default empty string on error
        }
    }

    const fetchTweets = async () => {
        if (!nextPage || loading || !hasMore) return;
        setLoading(true);
        setError('');
        try {
            const newsResponse = await axios.get(nextPage);
            if (newsResponse.data.message === "No more pages") {
                setHasMore(false);
                setLoading(false);
                return;
            }
            if (!newsResponse.data || !Array.isArray(newsResponse.data)) {
                setError('Invalid data structure from API');
                setLoading(false);
                return;
            }
            const newsData = await Promise.all(newsResponse.data.map(async (item) => {
                const company_name = await fetchCompanyData(item.ticker);
                return {
                    id: item.id,
                    company_name: company_name,
                    ticker: item.ticker,
                    date: item.date,
                    state: item.status,
                    source: item.publisher_title,
                    content: item.short_summary,
                    url: item.url
                };
            }));
            setLoading(true);
            setTweets(prevTweets => [...prevTweets, ...newsData]);
            setNextPage(prevNextPage => {
                const currentPage = parseInt(new URLSearchParams(new URL(window.location.href + prevNextPage).search).get('page'), 10);
                return `/api/news?page=${currentPage + 1}`;
            });
        } catch (error) {
            console.error('Error fetching news data: ', error);
            setError('End of threads :)');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && !loading) {
                fetchTweets();
            }
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        });

        const sentinel = document.createElement('div');
        document.querySelector('.feedContainer').appendChild(sentinel);

        observer.observe(sentinel);

        return () => {
            observer.unobserve(sentinel);
            sentinel.remove();
        };
    }, [loading, hasMore]);

    useEffect(() => {
        fetchTweets(); // Fetch initial tweets on component mount
    }, []);

    function generateImageWithText(text) {
        const canvas = document.createElement('canvas');
        canvas.width = 64;
        canvas.height = 64;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = "#FFFFFF";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.font = '22px Arial';
        ctx.fillStyle = "#000000";
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        const displayText = text.slice(0, 4);
        ctx.fillText(displayText, canvas.width / 2, canvas.height / 2);
        return canvas.toDataURL('image/png');
    }

    return (
        <div className="feedContainer">
            {tweets.map(tweet => (
                <div key={tweet.id} className="tweet">
                    <div className="tweet-logo">
                        <img 
                            src={`/static/logos/${tweet.ticker.toUpperCase()}.png`}
                            alt={`${tweet.ticker} logo`} 
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = generateImageWithText(tweet.ticker);
                            }}
                        />
                    </div>
                    <div className="tweet-content">
                    <div className="company-date">
                            <div className="company-name">
                                {tweet.company_name}
                            </div>
                            <div className="date">
                                {new Date(tweet.date).toLocaleDateString()}
                            </div>
                        </div>
                        <div className="ticker-status">
                            <div className="ticker">
                                {tweet.ticker}
                            </div>
                            <img className="state-img" src={tweet.state === 'up' ? stockUpImage : stockDownImage} alt={tweet.state === 'up' ? "Stock Up" : "Stock Down"} />
                        </div>
                        <a href={tweet.url} target="_blank" rel="noopener noreferrer" className="source">
                            {tweet.source}
                        </a>
                        <div className="content">{tweet.content}</div>
                    </div>
                </div>
            ))}
            {loading && <div className="spinner"></div>}
            {error && <p className="error">{error}</p>}
        </div>
    );
}

export default Feed;